.App {
  text-align: center;
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 18px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34,34,34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34,34,34);
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  background-color: #dbdbdb27;
  margin-top: 70px;
  height: 100vh;
  overflow: auto;
}

.div-for-table {
  background-color: white;
  width: 90%;
  border: 2px solid #9B820C;
  border-radius: 20px;
  margin: 50px auto;
  height: 80vh;
  overflow: auto;
}

.right-panel {
  background-color: white;
  float: right;
  width: 70%;
  border: 2px solid #9B820C;
  border-radius: 20px;
  margin: 50px 50px auto;
  height: 80vh;
  padding: 3%;
  overflow: auto;
}

.left-column {
  background-color: white;
  float: left;
  width: 20%;
  border: 2px solid #9B820C;
  border-radius: 20px;
  margin: 50px 0px auto 50px;
  height: 80vh;
  overflow: auto;
}

.data-div {
  border: 1px solid #9B820C;
  border-radius: 6px;
  padding: 1em;
  margin-top: 3em;
  margin-bottom: 3em;
}

.info-container {
  width: 95%;
  border: 1px solid #c9c9c7;
  border-radius: 10px;
  margin: 2em auto;
  overflow: auto;
  padding: 1em;
  max-height: 55vh;
}

.mini-info-container {
  width: 15%;
  border: 1px solid #c9c9c7;
  border-radius: 10px;
  margin: 2em auto;
  overflow: auto;
  padding: 1em;
  max-height: 55vh;
}

.graph-container {
  width: 70%;
  border: 1px solid #c9c9c7;
  border-radius: 10px;
  margin: 2em auto;
  overflow: auto;
  padding: 1em;
  max-height: 55vh;
  display: flex;
}

.title-left {
  padding: 4%;
  text-align: center;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  padding: .5em;
  height: 45vh;
}